export default [
   {
      title: "Blockchain in healthcare",
      images: "/static/images/chainsense/blog-img-1.jpg",
      description:
        "To put it in a most simple way, imagine an open space area with hundreds of computers interconnected. Now, those computers represent or are called nodes on the network. When a transaction is initiated, it gets represented as a block on the network. This block then passes through every node or computer. Those running the computers approve of the transaction making it valid. The block then gets recorded on every node because every node approved the transaction. The record is open for public view making it transparent.",
      tags: ["#Blockchain", "#Industry", "#Agriculture"],
      posted: "September 1, 2021",
      link:"https://www.chainsense.in/blockchain-in-healthcare/"
    },
    {
      title: "Werewolf Launches Decentralized Exchange Werewolf DEx",
      images: "/static/images/chainsense/blog-img-2.jpg",
      description:
        "Werewolf Exchange, the widely talked about Ddefi platform that recently unveiled its native NFT Marketplace has launched Werewolf DEx (WDEx), its native DEx (decentralized Exchange) â€“ Yet another integration in the Defi space. This launch is another step to deliver an all-around solution to a complete ecosystem surrounding the cryptosphere including NFTs with a whole line of new services still in their roadmap for the year.",
      tags: ["#Blockchain", "#Industry", "#Agriculture"],
      posted: "August 11, 2021",
      link:"https://www.chainsense.in/werewolf-launches-decentralized-exchange-werewolf-dex/"
    },
]